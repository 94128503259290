import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styledResponsive";

const Container = styled.div`
    display: none;
    ${breakpoints("display", ["none", "none", "flex", "flex"])};
    flex-direction: column;
    align-items: center;
    width: 50px;
    background-color: ${(props) => props.theme.colors.primaryTransparent};
`;

const SocialLink = styled.a`
    display: block;
    padding: 15px 10px;
`;


export const Contacts = () => (
    <Container>
        <SocialLink href="http://bit.ly/Leximoveis" target="_blank">
            <StaticImage src="../images/icons/whatsapp.png" alt="Whatsapp" />
        </SocialLink>
        <SocialLink href="mailto:contato@leximoveis.com.br" target="_blank">
            <StaticImage src="../images/icons/email.png" alt="E-mail" />
        </SocialLink>
        <SocialLink href="tel:(54) 3452-3455" target="_blank">
            <StaticImage src="../images/icons/phone.png" alt="Whatsapp" />
        </SocialLink>
        <SocialLink
            href="https://www.instagram.com/terroirvinhedos/"
            target="_blank"
        >
            <StaticImage src="../images/icons/instagram.png" alt="Whatsapp" />
        </SocialLink>
    </Container>
);
