import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import { Carousel } from "react-responsive-carousel";
import { StaticImage } from "gatsby-plugin-image";

export const ContentSlider = () => (
    <Carousel showThumbs={false} autoPlay infiniteLoop>
        <div>
            <StaticImage src="../images/condominio/slide-1.jpg" alt="Terroir" />
        </div>
        <div>
            <StaticImage src="../images/condominio/slide-2.jpg" alt="Terroir" />
        </div>
        <div>
            <StaticImage src="../images/condominio/slide-3.jpg" alt="Terroir" />
        </div>
        <div>
            <StaticImage src="../images/condominio/slide-4.jpg" alt="Terroir" />
        </div>
    </Carousel>
);
