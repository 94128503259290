import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styledResponsive";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const List = styled.ul`
    padding: 0;
    list-style: none;
`;

export const ListItem = styled.li`
    font-size: 20px;
    margin-bottom: 10px;
`;

export const Title = styled.h3`
    text-transform: uppercase;
    font-size: 28px;
    text-align: center;
    padding: 40px 0;
    padding-left: 0;
    font-weight: 300;
    ${breakpoints("text-align", ["center", "center", "left", "left"])};
`;

export const Items = () => (
    <Container>
        <Title>
            / 56 lotes
            <br />
            de 800m² a 1250m²
        </Title>
        <List>
            <ListItem>/ Acesso totalmente asfaltado</ListItem>
            <ListItem>/ Testadas de, no mínimo, 20m</ListItem>
            <ListItem>/ Ruas pavimentadas com bloquetos de concreto</ListItem>
            <ListItem>/ Alamedas totalmente arborizadas</ListItem>
            <ListItem>/ Rede lógica e elétrica subterrânea</ListItem>
            <ListItem>/ Pontos de recarga para veículo elétrico</ListItem>
            <ListItem>/ Heliponto</ListItem>
        </List>
    </Container>
);
