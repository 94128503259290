import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import "./carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { breakpoints } from "../styledResponsive";
import { Link } from "gatsby";
import { Contacts } from "./Contacts";

const Container = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    position: relative;
`;

const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Slogan = styled.h2`
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.alternative};
    margin-top: 40px;
    letter-spacing: 3px;
    font-size: 24px;
    ${breakpoints("font-size", ["24px", "24px", "16px", "32px"])};
`;

const Slide = styled.div`
    width: 100%;
    position: relative;
`;

const ImageContainer = styled.div`
    width: 219px;
    ${breakpoints("width", ["219px", "350px", "250px", "350px"])};
`;

const MobileSliderContainer = styled.div`
    ${breakpoints("display", ["block", "block", "none", "none"])};
`;

const DesktopSliderContainer = styled.div`
    display: none;
    ${breakpoints("display", ["none", "none", "block", "block"])};
`;

const CTAVinhedos = styled(Link)`
    color: ${(props) => props.theme.colors.alternative};
    font-weight: bold;
    font-size: 18px;
    display: block;
    margin-top: 45px;
`;

export const ContactsContainer = styled.div`
    right: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 999;
`;

export const Anchor = styled.a`
    width: 40px;
    ${breakpoints("width", ["40px", "40px", "40px", "50px"])};
    display: block;
    margin-top: 80px;
`;

export const Banner: React.FC = () => {
    return (
        <Container>
            <DesktopSliderContainer>
                <ContactsContainer>
                    <Contacts />
                </ContactsContainer>
                <Carousel showThumbs={false} autoPlay={false}>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/1-desktop.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <ImageContainer>
                                <StaticImage
                                    src="../images/terroir-logo.png"
                                    alt=""
                                />
                            </ImageContainer>
                            <Slogan>
                                Essencialmente <br />
                                Único
                            </Slogan>
                            <Anchor href="#main-content">
                                <StaticImage
                                    src="../images/icons/arrow-down.png"
                                    alt="Ver conteúdo"
                                    style={{
                                        maxWidth: "100%",
                                        display: "block",
                                    }}
                                />
                            </Anchor>
                        </Content>
                    </Slide>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/2-desktop.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <Slogan>
                                O vale dos
                                <br />
                                Vinhedos
                            </Slogan>
                            <CTAVinhedos to="/vale-dos-vinhedos">
                                ver mais
                            </CTAVinhedos>
                        </Content>
                    </Slide>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/3-desktop.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <Slogan>
                                Significado de
                                <br />
                                Terroir
                            </Slogan>
                            <CTAVinhedos to="/terroir">ver mais</CTAVinhedos>
                        </Content>
                    </Slide>
                </Carousel>
            </DesktopSliderContainer>
            <MobileSliderContainer>
                <Carousel showThumbs={false} autoPlay={false}>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/1-mobile.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <ImageContainer>
                                <StaticImage
                                    src="../images/terroir-logo.png"
                                    alt=""
                                />
                            </ImageContainer>
                            <Slogan>
                                Essencialmente <br />
                                Único
                            </Slogan>
                            <Anchor href="#main-content">
                                <StaticImage
                                    src="../images/icons/arrow-down.png"
                                    alt="Ver conteúdo"
                                    style={{
                                        maxWidth: "100%",
                                        display: "block",
                                    }}
                                />
                            </Anchor>
                        </Content>
                    </Slide>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/2-mobile.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <Slogan>
                                O vale dos
                                <br />
                                Vinhedos
                            </Slogan>
                            <CTAVinhedos to="/vale-dos-vinhedos">
                                ver mais
                            </CTAVinhedos>
                        </Content>
                    </Slide>
                    <Slide>
                        <StaticImage
                            style={{
                                maxWidth: "100%",
                                display: "block",
                            }}
                            src="../images/banners/3-mobile.jpg"
                            alt="Terroir"
                        />
                        <Content>
                            <Content>
                                <Slogan>
                                    Significado de
                                    <br />
                                    Terroir
                                </Slogan>
                                <CTAVinhedos to="/terroir">
                                    ver mais
                                </CTAVinhedos>
                            </Content>
                        </Content>
                    </Slide>
                </Carousel>
            </MobileSliderContainer>
        </Container>
        //<div>
        //     <StaticImage src="../images/banners/2-mobile.jpg" alt="Terroir" />
        // </div>
        // <div>
        //     <StaticImage src="../images/banners/3-mobile.jpg" alt="Terroir" />
        // </div> */}
    );
};
