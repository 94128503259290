import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";
import { Banner } from "../components/Banner";
import { ContentSlider } from "../components/ContentSlider";
import { Items } from "../components/Items";
import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";

export const MainContent = styled.main`
    width: 100%;
`;

interface BlockTextProps {
    filled?: boolean;
    justify?: string;
    align?: string;
}

export const FlexBlock = styled.div<{ bgc?: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    background-color: ${(props) =>
        props.bgc ? props.theme.colors.primary : "#fff"};
    ${breakpoints("flex-direction", [
        "column-reverse",
        "column-reverse",
        "row",
        "row",
    ])};
    margin: 40px auto;
    height: auto;
    overflow: hidden;
    justify-content: center;
    align-items: center;
`;

export const FlexBlockNoReverse = styled(FlexBlock)`
    flex-direction: column;
    ${breakpoints("flex-direction", ["column", "column", "row", "row"])};
`;

export const BlockImageContainer = styled.div`
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "60%", "60%"])};
`;

export const BlockImageContainerBig = styled.div`
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "60%", "60%"])};
`;

export const BlockContentContainer = styled.div`
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "40%", "40%"])};
`;

export const BlockTextContainer = styled.div<BlockTextProps>`
    padding: 60px 40px;
    text-align: center;
    height: 100%;
    ${breakpoints("text-align", ["center", "center", "left", "left"])};
    justify-content: ${(props) => (props.justify ? props.justify : undefined)};
    background-color: ${(props) =>
        props.filled ? props.theme.colors.primary : "#fff"};
    color: ${(props) => (props.filled ? "#fff" : "inherited")};
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "49%", "49%"])};
`;

export const BlockTextContainerInverted = styled(BlockTextContainer)`
    align-items: flex-end;
`;

export const Title = styled.h3`
    text-transform: uppercase;
    font-size: 28px;
    text-align: center;
    padding: 40px;
    font-weight: 300;
`;

export const BlockText = styled.p<{ align?: string }>`
    font-size: 24px;
    ${breakpoints("font-size", ["24px", "24px", "20px", "20px"])};
    font-weight: 100;
    padding: 20px;
    width: 100%;
    text-align: ${(props) => (props.align ? props.align : "left")};
    ${breakpoints("width", ["100%", "100%", "300px", "350px"])};
    letter-spacing: 2px;
    display: none;
    text-transform: uppercase;
    ${breakpoints("display", ["none", "none", "block", "block"])};
`;

export const BlockTextMobile = styled(BlockText)`
    display: block;
    text-align: center;
    ${breakpoints("display", ["block", "block", "none", "none"])};
`;

export const Perks = styled.div`
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "960px", "1200px"])};
    margin: auto;
    display: flex;
    flex-direction: column;
    ${breakpoints("flex-direction", ["column", "row", "row", "row"])};
    flex-wrap: wrap;
`;

export const PerkItem = styled.div`
    width: 100%;
    ${breakpoints("width", ["100%", "50%", "50%", "50%"])};
    padding: 10px;
    ${breakpoints("padding", ["10px", "10px", "40px", "40px"])};
`;

export const PerkText = styled.p`
    font-style: italic;
    font-size: 18px;
    padding: 24px;
    padding-bottom: 40px;
    text-align: center;
`;

const MapVale = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    padding: 120px 0;
    width: 100%;
`;

const DownloadLink = styled.a`
    background-color: ${(props) => props.theme.colors.primary};
    padding: 24px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
`;

export const PerkImage = styled.div``;

const IndexPage = () => (
    <Layout>
        <Banner />
        <MainContent id="main-content">
            <FlexBlock>
                <BlockImageContainer>
                    <StaticImage
                        src="../images/foto-condominio.jpg"
                        alt="Foto do Condomínio"
                    />
                </BlockImageContainer>
                <BlockTextContainer
                    justify="flex-start"
                    style={{ alignItems: "flex-start" }}
                >
                    <BlockText>
                        Um Condomínio Residencial exclusivo, cercado pelas
                        belezas naturais do Vale dos Vinhedos.
                    </BlockText>
                    <BlockTextMobile>
                        Um Condomínio Residencial exclusivo, cercado pelas
                        belezas naturais do Vale dos Vinhedos.
                    </BlockTextMobile>
                </BlockTextContainer>
            </FlexBlock>
            <FlexBlockNoReverse bgc>
                <BlockTextContainerInverted filled justify="flex-end">
                    <BlockText align="right">
                        Clube Privativo com Salão de Festas e Cave para guarda
                        de vinhos e realização de eventos.
                    </BlockText>
                    <BlockTextMobile>
                        Clube Privativo com Salão de Festas e Cave para guarda
                        de vinhos e realização de eventos.
                    </BlockTextMobile>
                </BlockTextContainerInverted>
                <BlockImageContainer>
                    <ContentSlider />
                </BlockImageContainer>
            </FlexBlockNoReverse>
            <Title>
                Completa infraestrutura de lazer, conforto e segurança.
            </Title>
            <Perks>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/perks-3.jpg"
                            alt="Infraestrutura de segurança com condomínio
    totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                    <PerkText>Fitness Center</PerkText>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/perks-4.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                    <PerkText>
                        Área social: Quiosque / Quadra Poliesportiva / Quadra de
                        Tênis / Parque Infantil / Pet Place / Brinquedoteca
                    </PerkText>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/perks-1.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                    <PerkText>
                        Infraestrutura de segurança com condomínio totalmente
                        murado, portaria 24 horas com monitoramento das ruas
                    </PerkText>
                </PerkItem>
                <PerkItem>
                    <PerkImage>
                        <StaticImage
                            src="../images/perks-2.jpg"
                            alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                        />
                    </PerkImage>
                    <PerkText>Rede lógica e elétrica subterrânea</PerkText>
                </PerkItem>
            </Perks>
            <FlexBlockNoReverse>
                <BlockImageContainer>
                    <a href="planta-terroir.pdf" download>
                        <StaticImage
                            src="../images/mapa-terreno.jpg"
                            alt="Mapa Lotes Terroir"
                        />
                    </a>
                    <DownloadLink href="planta-terroir.pdf" download>
                        Baixe a planta técnica
                    </DownloadLink>
                </BlockImageContainer>
                <BlockTextContainer>
                    <Items />
                </BlockTextContainer>
            </FlexBlockNoReverse>
            <MapVale>
                <a href="https://goo.gl/maps/qEg6A3BBeUvxh4Sb6" target="_blank">
                    <StaticImage
                        style={{
                            maxWidth: "100%",
                            display: "block",
                        }}
                        src="../images/mapa-terroir-condominio.png"
                        alt="Mapa de localização, Vale dos Vinhedos"
                    />
                </a>
            </MapVale>
        </MainContent>
    </Layout>
);

export default IndexPage;
